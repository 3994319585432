const CAR_LINK_BASE = '/samochody-osobowe'
const TRUCK_LINK_BASE = '/dostawcze'
const MOTORCYCLE_LINK_BASE = '/motocykle'
const PART_LINK_BASE = '/czesci'
const SERVICE_LINK_BASE = '/serwisy-samochodowe-uslugi'
const TOOL_LINK_BASE = '/narzedzia-warsztatowe-sprzedaz'
const AGRI_LINK_BASE = '/maszyny-rolnicze-sprzedaz'
const CONSTRUCTION_LINK_BASE = '/maszyny-budowlane-sprzedaz'
const TRAILER_LINK_BASE = '/przyczepy'
const HEAVY_LINK_BASE = '/samochody-ciezarowe'

const RENT_LINK_BASE = '/wynajem-samochodow-oferty'

const CAR_RENT_LINK_BASE = RENT_LINK_BASE + CAR_LINK_BASE
const TRUCK_RENT_LINK_BASE = RENT_LINK_BASE + TRUCK_LINK_BASE
const MOTORCYCLE_RENT_LINK_BASE = RENT_LINK_BASE + MOTORCYCLE_LINK_BASE
const AGRI_RENT_LINK_BASE = RENT_LINK_BASE + '/maszyny-rolnicze'
const TRAILER_RENT_LINK_BASE = RENT_LINK_BASE + TRAILER_LINK_BASE

const BUY_LINK_BASE = '/kupie-samochod-auto'
const BUY_LINK_BASE_2 = '/kupie'

const BUY_CAR_LINK_BASE = BUY_LINK_BASE + '/osobowe'
const BUY_TRUCK_LINK_BASE = BUY_LINK_BASE + TRUCK_LINK_BASE
const BUY_MOTORCYCLE_LINK_BASE = BUY_LINK_BASE + MOTORCYCLE_LINK_BASE
const BUY_PART_LINK_BASE = BUY_LINK_BASE + PART_LINK_BASE
const BUY_CONSTRUCTION_LINK_BASE = BUY_LINK_BASE + '/maszyny-budowlane'
const BUY_TRAILER_LINK_BASE = BUY_LINK_BASE + TRAILER_LINK_BASE
const BUY_TOOL_LINK_BASE = BUY_LINK_BASE_2 + '/narzedzia-warsztatowe'
const BUY_AGRI_LINK_BASE = BUY_LINK_BASE_2 + '/maszyny-rolnicze'

const isMakeModelBased = (path) => Boolean({
    [CAR_LINK_BASE]: 1,
    [TRUCK_LINK_BASE]: 1,
    [MOTORCYCLE_LINK_BASE]: 1,
    [AGRI_LINK_BASE]: 1,
    [CONSTRUCTION_LINK_BASE]: 1,
    [TRAILER_LINK_BASE]: 1,
    [HEAVY_LINK_BASE]: 1,

    [BUY_CAR_LINK_BASE]: 1,
    [BUY_TRUCK_LINK_BASE]: 1,
    [BUY_MOTORCYCLE_LINK_BASE]: 1,
    [BUY_CONSTRUCTION_LINK_BASE]: 1,
    [BUY_TRAILER_LINK_BASE]: 1,
    [BUY_AGRI_LINK_BASE]: 1,

    [PART_LINK_BASE]: 0,
    [SERVICE_LINK_BASE]: 0,
    [TOOL_LINK_BASE]: 0,

    [CAR_RENT_LINK_BASE]: 0,
    [TRUCK_RENT_LINK_BASE]: 0,
    [MOTORCYCLE_RENT_LINK_BASE]: 0,
    [AGRI_RENT_LINK_BASE]: 0,
    [TRAILER_RENT_LINK_BASE]: 0,

    [BUY_PART_LINK_BASE]: 0,
    [BUY_TOOL_LINK_BASE]: 0,
}[path])

module.exports = {
    CAR_LINK_BASE,
    TRUCK_LINK_BASE,
    MOTORCYCLE_LINK_BASE,
    PART_LINK_BASE,
    SERVICE_LINK_BASE,
    TOOL_LINK_BASE,
    AGRI_LINK_BASE,
    CONSTRUCTION_LINK_BASE,
    TRAILER_LINK_BASE,
    HEAVY_LINK_BASE,
    RENT_LINK_BASE,
    CAR_RENT_LINK_BASE,
    TRUCK_RENT_LINK_BASE,
    MOTORCYCLE_RENT_LINK_BASE,
    AGRI_RENT_LINK_BASE,
    TRAILER_RENT_LINK_BASE,
    BUY_LINK_BASE,
    BUY_LINK_BASE_2,
    BUY_CAR_LINK_BASE,
    BUY_TRUCK_LINK_BASE,
    BUY_MOTORCYCLE_LINK_BASE,
    BUY_PART_LINK_BASE,
    BUY_CONSTRUCTION_LINK_BASE,
    BUY_TRAILER_LINK_BASE,
    BUY_TOOL_LINK_BASE,
    BUY_AGRI_LINK_BASE,

    isMakeModelBased,
}